import gql from 'graphql-tag'

const detailResponse = `
  id name isActive
  employeeId isPassword isGoogle
  groups {id code name}
`

export const LIST_USER = (templateType) => gql`query LIST_USER ($userType: String!, $q: FilterInput) {
  users: listUser${templateType} (userType: $userType, q: $q) {
    id name isActive isGoogle isPassword
  }
}`

export const DETAIL_USER = (templateType) => gql`query DETAIL_USER ($userType: String!, $userId: Int!) {
  user: detailUser${templateType} (userType: $userType, userId: $userId) {${detailResponse}}
}`

export const CREATE_USER = (templateType) => gql`mutation CREATE_USER ($userType: String!, $input: User${templateType}Input!) {
  createUser: createUser${templateType} (userType: $userType, input: $input) {${detailResponse}}
}`

export const UPDATE_USER = (templateType) => gql`mutation UPDATE_USER ($userType: String!, $userId: Int!, $input: User${templateType}Input!) {
  updateUser: updateUser${templateType} (userType: $userType, userId: $userId, input: $input) {${detailResponse}}
}`

export const DESTROY_USER = (templateType) => gql`mutation DESTROY_USER ($userType: String!, $userId: Int!) {
  destroyUser: destroyUser${templateType} (userType: $userType, userId: $userId) {id}
}`

export const CREATE_GROUP_USER = (templateType) => gql`mutation CREATE_GROUP_USER ($userType: String!, $groupId: Int!, $userId: Int!) {
  createGroupUser: createGroupUser${templateType} (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const DESTROY_GROUP_USER = (templateType) => gql`mutation DESTROY_GROUP_USER ($userType: String!, $groupId: Int!, $userId: Int!) {
  destroyGroupUser: destroyGroupUser${templateType} (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const RESET_USERNAME = (templateType) => gql`mutation RESET_USERNAME ($userType: String!, $userId: Int!, $username: String!, $password: String!) {
  resetUserUsername: resetUser${templateType}Username (userType: $userType, userId: $userId, username: $username, password: $password)
}`

export const GENERATE_REGISTER_TOKEN = (templateType) => gql`mutation GENERATE_REGISTER_TOKEN ($userType: String!, $userId: Int!) {
  token: generateUser${templateType}GoogleRegisterToken (userType: $userType, userId: $userId)
}`

export const REVOKE_GOOGLE_REGISTER = (templateType) => gql`mutation REVOKE_GOOGLE_REGISTER ($userType: String!, $userId: Int!) {
  result: revokeUser${templateType}GoogleRegister (userType: $userType, userId: $userId)
}`

export const LIST_GROUP = (templateType) => gql`query LIST_GROUP ($userType: String!, $q: FilterInput) {
  groups: listUser${templateType}DropdownGroup (userType: $userType, q: $q) {
    id code name
  }
}`

export const DROPDOWN_EMPLOYEES = (templateType) => gql`query DROPDOWN_EMPLOYEES ($userType: String!, $q: FilterInput) {
  employees: listUser${templateType}DropdownEmployee (userType: $userType, q: $q) {
    id type code name
  }
}`

export const LIST_API_KEY = (templateType) => gql`query LIST_API_KEY ($userType: String!, $userId: Int!) {
  apiKeys: listUser${templateType}ApiKey (userType: $userType, userId: $userId) {
    id type name accessToken
    createdAt createdBy createdUser {id name}
  }
}`

export const GENERATE_API_KEY = (templateType) => gql`mutation GENERATE_API_KEY ($userType: String!, $userId: Int!, $name: String!) {
  apiKey: generateUser${templateType}ApiKey (userType: $userType, userId: $userId, name: $name) {
    id type name accessToken
    createdAt createdBy createdUser {id name}
  }
}`

export const REVOKE_API_KEY = (templateType) => gql`mutation REVOKE_API_KEY ($userType: String!, $userId: Int!, $apiKeyId: Int!) {
  apiKey: revokeUser${templateType}ApiKey (userType: $userType, userId: $userId, apiKeyId: $apiKeyId) {
    id
  }
}`