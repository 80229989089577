<template>
  <div class="">
    <button
      type="button"
      class="btn btn-danger"
      @dblclick="revokeRegister">
      ยกเลิก Google Login
    </button>
  </div>
</template>

<script>
import {
  REVOKE_GOOGLE_REGISTER
} from './graph'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {

    }
  },
  methods: {
    revokeRegister () {
      this.$apollo.mutate({
        mutation: REVOKE_GOOGLE_REGISTER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('ยกเลิกสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
