<template>
  <div class="">
    <div 
      v-if="method === 'edit' && $auth.hasRole(`user:${userType}:add:apiKey`)" 
      class="mb-3">

      <div class="form-row">
        <sgv-input-text 
          class="col-12 col-sm-6 col-md-4"
          v-model="name" 
          label="ชื่อ"
          :validations="[
            {text: 'required!', value: $v.name.$dirty && $v.name.$error}
          ]">
        </sgv-input-text>
      </div>

      <button 
        type="button" 
        class="btn btn-success"
        @click="generateApiKey">
        สร้าง ApiKey
      </button>
    </div>

    ApiKey:
    <ol>
      <li v-for="apiKey in apiKeys" :key="apiKey.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @dblclick="revokeApiKey(apiKey.id)">
        </fa>&nbsp;
        {{apiKey.name}}: 
        <span @click="copyText(apiKey.accessToken)" class="px-2 text-success pointer">ApiKey</span>
        {{ apiKey.createdAt | date }} ({{ apiKey.createdUser.name }})
      </li>
    </ol>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  LIST_API_KEY,
  GENERATE_API_KEY,
  REVOKE_API_KEY
} from './graph'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      apiKeys: [],
      name: ''
    }
  },
  validations: {
    name: { required },
  },
  apollo: {
    apiKeys: {
      query () {
        return LIST_API_KEY(this.templateType)
      },
      variables () {
        return {
          userType: this.userType,
          userId: this.userId
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    generateApiKey () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: GENERATE_API_KEY(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          name: this.name
        }
      })
      .then(() => {
        this.$apollo.queries.apiKeys.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    revokeApiKey (apiKeyId) {
      this.$apollo.mutate({
        mutation: REVOKE_API_KEY(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          apiKeyId
        }
      })
      .then(() => {
        this.$apollo.queries.apiKeys.refetch()
        this.$v.$reset()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    copyText(txt) {
      this.$copyText(txt)
      this.$toasted.global.success("Copied!")
    },
  }
}
</script>

<style lang="css" scoped>
</style>
