<template>
  <div class="">
    <button
      type="button"
      class="btn btn-warning"
      @click="generateRegisterToken">
      สร้างรหัสลงทะเบียน Google
    </button>

    <div v-if="registerToken" class="mt-3">
      รหัสลงทะเบียน: {{registerToken}}
    </div>
  </div>
</template>

<script>
import {
  GENERATE_REGISTER_TOKEN
} from './graph'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      registerToken: '',
    }
  },
  methods: {
    generateRegisterToken () {
      this.$apollo.mutate({
        mutation: GENERATE_REGISTER_TOKEN(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId
        }
      })
      .then(res => {
        this.registerToken = res.data.token
      })
      .catch(this.$toasted.global.error)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
